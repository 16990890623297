









































































































































































































































import BaseAvatar from "@/components/Base/BaseAvatar.vue";
import BaseCard from "@/components/Base/BaseCard.vue";
import BaseDialog from "@/components/Base/BaseDialog.vue";
import BaseLink from "@/components/Base/BaseLink.vue";
import InputTextarea from "@/components/Input/InputTextarea.vue";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        BaseCard,
        BaseAvatar,
        BaseLink,
        BaseDialog,
        InputTextarea,
    },
})
export default class AdminUserListItem extends Vue {
    @Prop() user!: any;

    public isShowLockDialog: boolean = false;
    public isShowUnlockDialog: boolean = false;
    public isShowDeleteDialog: boolean = false;
    public isShowResetDialog: boolean = false;

    public form: any = {
        text: "Alasan",
        placeholder: "Alasan dikunci",
        withLabel: true,
        value: "lock_reason",
    };

    public formData: any = {};

    get userDisplayName() {
        let displayName = this.user.username;
        const hasProfile = this.user && this.user.profile;

        if (hasProfile) displayName = this.user.profile.display_name;

        return displayName;
    }

    isAdmin(user: any) {
        const { role } = user;
        const isAdmin = role.name === "ADMIN";

        return isAdmin;
    }

    goToUser(user: any) {
        let route: string = "/app/perusahaan/";

        if (user.role.name === "PROPOSER") route = "/app/pengusul/";

        return route + user.id;
    }

    statistic(user: any) {
        let statistic: number | string = "-";
        let suffix: string = "";

        const isProposer = user.role.name === "PROPOSER";
        const isCompany = user.role.name === "COMPANY";

        if (isProposer) {
            statistic =
                user.proposals && user.proposals.length
                    ? user.proposals.length
                    : 0;

            suffix = " proposal telah dibuat";
        }

        return statistic + suffix;
    }

    showLockDialog() {
        this.isShowLockDialog = true;
    }

    showUnlockDialog() {
        this.isShowUnlockDialog = true;
    }

    showDeleteDialog() {
        this.isShowDeleteDialog = true;
    }

    showResetDialog() {
        this.isShowResetDialog = true;
    }

    async lockUser() {
        const payload = {
            user: this.user,
            lock_reason: this.formData.lock_reason,
        };
        await this.$store.dispatch("users/lockUser", payload);
        await this.$store.dispatch("users/getAllUser");

        this.isShowLockDialog = false;
    }

    async unlockUser() {
        await this.$store.dispatch("users/unlockUser", this.user);
        await this.$store.dispatch("users/getAllUser");

        this.isShowUnlockDialog = false;
    }

    async deleteUser() {
        await this.$store.dispatch("users/deleteUser", this.user);
        await this.$store.dispatch("users/getAllUser");

        this.isShowDeleteDialog = false;
    }

    async resetPassword() {
        await this.$store.dispatch("users/resetPassword", this.user);

        this.isShowResetDialog = false;
    }
}
